import * as React from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import { useSelector } from 'react-redux';
import { API_URL } from '../../../configs/endpoints';
import { getAccessToken } from '../../../auth/utils';
export default function HelpDocs() {
    const navigate = useNavigate()
    const user = useSelector(state => state.user)
    const LoginXERO = () => {
        window.location.href = API_URL + "/auth/xero?next=" + window.location.pathname + "&accessToken=" + getAccessToken();
    }

    return (
        <>
        { [4, 26].includes(user?.id) && 
        <IconButton onClick={LoginXERO}>
            <AccessibilityNewIcon />
        </IconButton>
        }
        

        <Tooltip title="Help/Docs">
            <IconButton 
            onClick={() => navigate("/docx")}
                size="large"
                aria-label="Help/Docs"
                color="inherit"

                id="notification-button"
            >
                    <HelpOutlineOutlinedIcon sx={{ color: "#555" }} />
            </IconButton>
        </Tooltip>

           
        </>
    );
}