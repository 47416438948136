import instance from "../../../../auth/utils/useJwt";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";

import { useParams } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import { useEffect, useState } from 'react';
import CustomInputLabel from '../../../../components/forms/CustomInputLabel';
import GeneralDialog from '../../../../components/Dialogs/CustomDialog';
import { Stack } from '@mui/material';

const STATUS_OPTIONS = [
    { label: "-- choose status --", value: "" },
    { label: "ACCEPTED", value: "ACCEPTED" },
    { label: "ASSISTED", value: "ASSISTED" },
    { label: "BY_OTHERS", value: "BY_OTHERS" },
    { label: "COMPLETED", value: "COMPLETED" },
    { label: "CURRENT", value: "CURRENT" },
    { label: "DECEASED", value: "DECEASED" },
    { label: "FACILITATED", value: "FACILITATED" },
    { label: "INVALID_OPS", value: "INVALID_OPS" },
    { label: "MISSING", value: "MISSING" },
    { label: "MONITOR", value: "MONITOR" },
    { label: "PENDING", value: "PENDING" },
    { label: "REFERRAL", value: "REFERRAL" },
    { label: "REFERRED", value: "REFERRED" },
    { label: "REJECTED", value: "REJECTED" }
]


export default function OverrideStatus(props) {
    const { open, setOpen, patientData } = props
    const { register, reset, control, handleSubmit, watch, setValue,
        formState: { errors },
    } = useForm();
    const handleClose = () => {
        setOpen(false)
    }

    const [apiReq, setApiReq] = useState({
        loading: false, error: null
    })

    const { patient_id, case_id } = useParams()

    const onSubmit = (data) => {
        data.patient_id = patientData.id
        data.case_id = parseInt(case_id)
        setApiReq({ loading: true, error: null })

        instance.post("case/override-status", data).then(res => {
            console.log("Done")
            setApiReq({ loading: false, error: null })
            props.showSnackbar("Updated", "success")
            props.setOpen(false)
        }).catch(err => {
            console.log("Error")
            setApiReq({ loading: false, error: "Error updating status" })
            props.showSnackbar("Error updating status", "success")
        })
    }

    const onError = (error) => {

    }

    useEffect(() => {
        reset({
            "status": patientData.case_status
        })
    }, [patientData])


    return (

        <GeneralDialog open={open} handleClose={handleClose} title="Status Override"
            disabled={apiReq.loading} onFormSubmit={handleSubmit(onSubmit, onError)}
            actions={
                <Stack direction="row" spacing={2}>
                    <Button onClick={handleClose} disabled={apiReq.loading} variant="outlined">Cancel</Button>
                    <Button type="submit" disabled={apiReq.loading} variant="contained">
                        {
                            apiReq.loading ? `SAVING` : `SAVE`
                        }
                    </Button>
                </Stack>
            }>

            <Box>
                <Grid container spacing={2} columns={12}>
                    <Grid item md={6}>
                        <CustomInputLabel>New Status</CustomInputLabel>
                        <FormControl fullWidth>
                            <Controller
                                name="status"
                                control={control}
                                defaultValue={null}
                                rules={{ required: 'Status is required!' }}
                                render={({ field: { onChange, value } }) => (
                                    <Autocomplete
                                        options={STATUS_OPTIONS}
                                        value={value}
                                        onChange={(_, newValue) => onChange(newValue)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                size="small"
                                                variant="outlined"
                                                fullWidth
                                                error={Boolean(errors.country) || !watch('status')}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </FormControl>
                        <CustomInputLabel>Select a new status to assign the case.</CustomInputLabel>
                    </Grid>
                    <Grid item md={6}>
                        <CustomInputLabel>Action</CustomInputLabel>

                        <TextField size="small" fullWidth {...register('action')} />

                        <CustomInputLabel>Update the action if required.</CustomInputLabel>
                    </Grid>

                </Grid>
                <Box sx={{ mt: 3 }}>
                    <CustomInputLabel>Comment</CustomInputLabel>

                    <TextField size="small" fullWidth {...register('comment')} />

                    <Typography sx={{ fontSize: '13px', color: "#777" }}>Optionally, you may provide a comment for the override that will be visible in the change history.</Typography>
                </Box>

                { apiReq.error && <Typography color="red">{apiReq.error}</Typography> }
            </Box>
        </GeneralDialog>
    )
}